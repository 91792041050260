<template>
  <alert
    v-model:isOpen="alertIsOpen"
    :text="
      `只今、準備中です。<br />
再開するまでお待ちください。`
    "
    ok-text="了解する"
    :useHTML="true"
    @ok="waitOk"
    :has-close="false"
  ></alert>
</template>

<script>
import Alert from './Alert'
import { defineComponent, ref, watch, computed, onMounted } from 'vue'
import store from '../store'
export default defineComponent({
  name: 'StopAlert',
  components: {
    Alert
  },
  setup() {
    const alertIsOpen = ref(false)
    const liveIsStop = computed(() => store.getters.liveIsStop)
    const liverNickName = computed(
      () => store.getters.meetingEvent?.liver.nickName
    )
    // setTimeout(() => {
    //   store.dispatch('setLiveIsStop', true)
    // }, 5000)
    // setTimeout(() => {
    //   store.dispatch('setLiveIsStop', false)
    // }, 15000)
    const waitOk = () => {
      alertIsOpen.value = false
    }
    onMounted(async () => {
      alertIsOpen.value = liveIsStop.value
    })
    watch(liveIsStop, val => {
      alertIsOpen.value = val
    })
    return { alertIsOpen, waitOk, liverNickName }
  }
})
</script>

<style></style>
