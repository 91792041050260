<template>
  <ion-page :style="chatRoomBody" class="chat-room-page ion-page">
    <ion-header class="ion-no-border">
      <ion-toolbar v-if="meetingEvent">
        <div>
          <ion-title class="tc cWhite f12 clearfix chat-room-header-title"
            ><img
              :src="meetingEvent.liver.avatar"
              class="chat-room-header-avatar"
            />
            <div class="dib chat-room-title-body">
              <div class="tl ellipsis">
                {{ meetingEvent.liver.nickName + 'のチャットルーム' }}
              </div>
            </div>
          </ion-title>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="chat-room-ion-content">
      <div
        class="chat-room-rebut-btn"
        @click="buyTicket"
        v-if="rebuyFlag === '1'"
      >
        チケットを再度購入&nbsp; &nbsp; >
      </div>
      <div
        class="common-body-720-100 chat-room-body"
        v-if="meetingeEnterInfo && im"
      >
        <div
          class="chat-room-ticket-body"
          v-if="
            enterTicket &&
              meetingEvent &&
              meetingUser &&
              eventCountDownText !== ''
          "
        >
          <div class="chat-room-ticket-left"></div>
          <!-- tricket あり -->
          <div class="chat-room-ticket-mid clearfix">
            <div class="tc chat-room-tricket-info-title">
              <div>開始まで暫くお待ち下さい</div>
              <div
                class="tc chat-room-tricket-count-body"
                v-if="meetingeEnterInfo"
              >
                <img
                  src="/img/ticketOutline.png"
                  class="chat-room-tricket-count-ico"
                />
                権利枚数 × {{ meetingeEnterInfo.ticketCount }}枚
              </div>
            </div>
            <div class="chat-room-tricket-avatar-body">
              <img :src="avatar" class="chat-room-tricket-avatar" />
            </div>
            <div class="chat-room-tricket-info-body clearfix">
              <div class="chat-room-tricket-user ellipsis fl">
                <ion-icon
                  :icon="peopleOutline"
                  class="vertical-align-1"
                ></ion-icon>
                あなたの番まであと{{ beforeCount }}人
              </div>
              <div class="chat-room-tricket-time ellipsis fl">
                <ion-icon
                  :icon="timeOutline"
                  class="vertical-align-1"
                ></ion-icon>
                {{ liveIsStop ? '準備中' : eventCountDownText }}
              </div>
            </div>
          </div>
          <div class="chat-room-ticket-right"></div>
        </div>

        <!-- tricket なし -->
        <!-- v-else-if="meetingEvent && meetingUser" -->
        <div
          class="chat-room-ticket-body chat-room-white-ticket"
          v-else-if="meetingEvent && meetingUser && !enterTicket"
        >
          <div class="chat-room-ticket-left"></div>
          <div class="chat-room-ticket-mid clearfix">
            <div class="chat-room-tricket-avatar-body">
              <img :src="avatar" class="chat-room-tricket-avatar" />
            </div>
            <div class="chat-room-tricket-info-body tc chat-room-no-tricket">
              <div class="f12 chat-room-tricket-room-end-title">
                {{ meetingEvent.liver.nickName }}の{{ eventExpress }}時間終了
              </div>
              <div class="chat-room-tricket-room-info-box">
                <div class="f12">チャットルーム</div>
                <div class="f12">
                  {{ timeFormat(meetingEvent.endTime) }}まで
                </div>
              </div>
            </div>
          </div>
          <div class="chat-room-ticket-right"></div>
        </div>
        <div class="chat-room-audience-list-body" v-show="audienceShow">
          <div
            class="chat-room-audience-list-item"
            v-for="(item, index) in audience"
            :key="index"
          >
            <img :src="item.avatar" class="chat-room-audience-list-avatar" />
          </div>
        </div>
        <div class="chat-room-icon-body" ref="chatRoomIconBody">
          <div
            v-for="item in animeDoingIconList"
            :key="item.id"
            class="chat-room-icon-item chat-room-send-icon"
            :style="{
              left: `${item.left}%`,
              transform: `scale(${item.scale},${item.scale})`,
              animationDuration: icoAnimationDuration
            }"
            @animationend="deletAnimeList(item.id)"
          >
            <img
              :src="`/img/${item.type}.png`"
              class="chat-room-icon-item chat-room-icon-img"
            />
          </div>
        </div>
        <div class="chat-room-bottom-chat-bar-body">
          <div class="chat-room-bottom-chat-bar-box">
            <div
              class="chat-room-bottom-chat-see-new-comment"
              v-show="chatMousDownFlag"
            >
              <button
                type="button"
                @click="setChatMousDownFlag"
                class="chat-room-bottom-chat-see-new-comment-btn"
              >
                新しいコメントをみる
                <ion-icon
                  :icon="chevronDownOutline"
                  class="chat-room-bottom-chat-see-new-comment-icon"
                ></ion-icon>
              </button>
            </div>
            <div
              class="chat-room-bottom-chat-list"
              :class="{ type_b: userType === 'b' }"
              id="chatContainer"
              @mousewheel="chatTouch"
              @touchmove="chatTouch"
            >
              <div class="chat-room-bottom-chat-box">
                <div
                  class="chat-room-bottom-chat-item"
                  v-for="(item, index) in chatList"
                  :key="index + item.name"
                >
                  <div class="chat-room-bottom-chat-avatar-box">
                    <img
                      :src="item.avatar"
                      class="chat-room-bottom-chat-avatar"
                    />
                  </div>
                  <div>
                    <div class="chat-room-bottom-chat-name">
                      {{ item.name }}
                    </div>
                    <div class="chat-room-bottom-chat-msg">{{ item.msg }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="chat-room-bottom-chat-input-body"
              v-if="userType === 'c'"
            >
              <input
                type="text"
                class="chat-room-bottom-chat-input"
                placeholder="コメントする"
                v-model="chatContent"
                @focus="chatFocus"
                @blur="chatBlur"
                @keydown="keydownSend"
              />
              <button
                type="button"
                class="chat-room-bottom-chat-input-send"
                :class="{ active: chatContent !== '' }"
                @click="send"
                @keydown="keydownSend"
              >
                <!-- @compositionstart="composing = true"
                @compositionend="composing = false" -->
                送信
              </button>
            </div>
            <div class="chat-room-right-tool-bar-body" v-if="userType === 'c'">
              <div class="chat-room-right-circle-body">
                <div
                  class="chat-room-right-circle type-img"
                  :class="{ 'icon-active': goodIconAnime }"
                  @animationend="goodIconAnime = false"
                  @click="clickIcon('good')"
                >
                  <img src="/img/good.png" />
                </div>
              </div>
              <div class="chat-room-right-circle-body">
                <div
                  class="chat-room-right-circle type-img"
                  :class="{ 'icon-active': likeIconAnie }"
                  @animationend="likeIconAnie = false"
                  @click="clickIcon('like')"
                >
                  <img src="/img/like.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
    <alert
      v-model:isOpen="alertIsOpen"
      text="ポイントが足りないよ！チャージしますか？"
      ok-text="チャージ"
      @ok="openCharge"
    ></alert>
    <StopAlert v-if="enterTicket" />
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonTitle
} from '@ionic/vue'
import { defineComponent } from 'vue'
import {
  close,
  timeOutline,
  personOutline,
  chatbubbleSharp,
  giftSharp,
  peopleOutline,
  shareOutline,
  chatbubbleOutline,
  chevronDownOutline,
  ticket,
  camera
} from 'ionicons/icons'
// eslint-disable-next-line no-unused-vars
import scrollIntoView from 'scroll-into-view-if-needed'
import { throttle, random, cloneDeep } from 'lodash'
import Alert from '../../../components/Alert'
import StopAlert from '../../../components/StopAlert.vue'
import { addRefreshJWT, creatColorByNickName } from '../../../utils/utils'
import { authApi } from '../../../api'
// import TWebLive from 'tweblive'
import TIM from 'tim-js-sdk'
import moment from 'moment'
moment.locale('ja')

export default defineComponent({
  name: 'ChatRoom',
  data() {
    return {
      payIsOpen: false,
      payDialogKey: 1,
      payPoint: '0',
      payJpy: '0',
      pricingId: null,
      pointIsOpen: false,
      alertIsOpen: false,
      rebuyFlag: this.$route.query.rebuy,
      testStartTime: new Date().getTime() + 120000,
      beforeCount: 0,
      maxUserCount: 50,
      id: this.$route.params.id,
      maxAnimeIconCount: 50,
      iconClickCount: 0,
      animeDoingIconList: [],
      animeWaitIconList: [],
      isOpen: false,
      audienceShow: false,
      audience: [],
      userType: this.$route.meta.userType,
      openShare: false,
      openGift: false,
      chatContent: '',
      chatMaxCount: 20,
      chatClearTimer: null,
      chatMousDownFlag: false,
      listScrollTimer: null,
      camera,
      chatList: [],
      backgroundUrl: '/img/photoSample.jpg',
      close,
      timeOutline,
      personOutline,
      chatbubbleSharp,
      giftSharp,
      peopleOutline,
      shareOutline,
      chatbubbleOutline,
      chevronDownOutline,
      ticket,
      eventCountDownText: '',
      eventCountDownTextTimer: null,
      eventsBeforeCountTimer: null,
      icoAnimationDuration: '2.5s',
      icoAnimationDurationFlag: false,
      chatFocusFlag: false,
      goodIconAnime: false,
      likeIconAnie: false,
      composing: false
    }
  },
  props: {},
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonTitle,
    Alert,
    StopAlert
  },
  ionViewDidEnter() {
    if (this) {
      this.setAnimeIcon()
      this.setCountDown()
      this.eventsBeforeCountTimeSet()
    }
    // setTimeout(() => {
    //   this.$router.replace({
    //     path: '/user/meeting/1/streaming'
    //   })
    // }, 5000)
    // this.getChatMsg()
    //   TODO:テスト用
    // setTimeout(() => {
    //   this.emuGetChat(0)
    // }, 1000)
    // this.addTestIcon()
  },
  computed: {
    avatar() {
      if (this.meetingUser) {
        return `https://ui-avatars.com/api/?name=${encodeURI(
          this.$store.getters.meetingUser.nickName
        )}&color=fff&background=${creatColorByNickName(
          this.$store.getters.meetingUser.nickName
        )}`
      }
      return ''
    },
    iOSkeyBoardIsOpen() {
      return this.iOS && this.chatFocusFlag
    },
    iOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      )
    },
    eventExpress() {
      return this.$store.getters.eventExpress
    },
    giftBtnStatus() {
      // 0:ticketなし,1:ticketあり,プレゼントあり,2：ticketあり、プレゼントなし
      let status = 0
      if (this.meetingeEnterInfo) {
        if (this.meetingeEnterInfo.ticket) {
          status = 2
          if (this.meetingeEnterInfo.ticket.gift) {
            status = 1
          }
        }
      }
      return status
    },
    liveIsStop() {
      return this.$store.getters.liveIsStop
    },
    chatRoomBody() {
      let imageBackground = `none`
      if (this.meetingEvent) {
        if (this.meetingEvent.imageBackground) {
          imageBackground = `url(${this.meetingEvent.imageBackground})`
        }
      }
      return {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),${imageBackground}`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundColor: '#e0e0e0',
        minHeight: '100%'
      }
    },
    meetingEvent() {
      return this.$store.getters.meetingEvent
    },
    meetingeEnterInfo() {
      return this.$store.getters.meetingeEnterInfo
    },
    enterTicket() {
      try {
        return this.meetingeEnterInfo.ticket
      } catch (error) {
        return undefined
      }
    },
    meetingUser() {
      return this.$store.getters.meetingUser
    },
    im() {
      return this.$store.getters.im
    },
    msg() {
      return this.$store.getters.msg
    },
    serverDelay() {
      return this.$store.getters.serverDelay
    }
  },
  watch: {
    $route() {
      if (this.$route.name !== 'ChatRoom') {
        if (this.eventCountDownTextTimer) {
          clearInterval(this.eventCountDownTextTimer)
        }
        if (this.eventsBeforeCountTimer) {
          clearInterval(this.eventsBeforeCountTimer)
        }
        if (this.icoAnimationDurationFlag) {
          window.removeEventListener('resize', this.setIcoAnimationDuration)
          this.icoAnimationDurationFlag = false
        }
      }
    },
    chatList: {
      handler: function() {
        this.throttleChat()
      },
      deep: true
    },
    meetingeEnterInfo: {
      handler: function(val) {
        if (val) {
          this.beforeCount = val.beforeCount || 0
        }
      },
      deep: true
    },
    msg: {
      handler: function(val) {
        if (val) {
          const { type, data } = val
          switch (type) {
            case 'chat':
              this.addNewMsg(data)
              break
            case 'custom':
              this.customMsg(data)
              break
            default:
              break
          }
        }
      },
      deep: true
    }
  },
  methods: {
    chatFocus() {
      this.chatFocusFlag = true
      setTimeout(() => {
        this.setChatCpntainer()
      }, 200)
    },
    chatBlur() {
      this.chatFocusFlag = false
    },
    setChatMousDownFlag() {
      this.chatMousDownFlag = false
      this.throttleChat()
    },
    setIcoAnimationDuration() {
      if (this.$refs.chatRoomIconBody) {
        const height = this.$refs.chatRoomIconBody.clientHeight
        const speed = height / 100
        const duration = speed.toFixed(2) + 's'
        this.icoAnimationDuration = duration
      } else {
        this.icoAnimationDuration = '2.5s'
      }
    },
    eventsBeforeCountTimeSet() {
      if (!this.meetingeEnterInfo) {
        setTimeout(() => {
          this.eventsBeforeCountTimeSet()
        }, 500)
        return false
      }
      this.eventsBeforeCount()
    },
    doEventsBeforeCount() {
      this.eventsBeforeCountTimer = setTimeout(() => {
        if (!this.meetingeEnterInfo) {
          setTimeout(() => {
            this.doEventsBeforeCount()
          }, 500)
          return false
        }
        const startTime = this.meetingeEnterInfo?.ticket?.startTime || 0
        const s = moment(startTime).diff(
          new Date().getTime() - this.serverDelay,
          'seconds'
        )
        if (s >= 0) {
          this.eventsBeforeCount()
        }
      }, 5000)
    },
    eventsBeforeCount() {
      authApi
        .eventsBeforeCount(this.id)
        .then(res => {
          if (res.data >= 0) {
            this.beforeCount = res.data
          }
        })
        .catch(error => {
          console.error(error)
          const status = error.response.status
          switch (status) {
            case 401:
              addRefreshJWT(this.eventsBeforeCount)
              break
            default:
              break
          }
        })
        .finally(() => {
          this.doEventsBeforeCount()
        })
    },
    timeFormat(time) {
      return moment(time).format('llll')
    },
    closeMeeting() {
      this.$router.replace({ name: 'Entrance' })
    },
    buyTicket() {
      this.$router.push({ path: `/user/ticketdetail/${this.id}` })
    },
    chatTouch() {
      // this.$store.dispatch('addUpdateKey', this.$store.getters.updateKey + 1)
      const scrollHeight = document.getElementById('chatContainer').scrollHeight
      const height = document.getElementById('chatContainer').clientHeight
      // console.log(scrollHeight, height)
      if (scrollHeight > height) {
        this.chatMousDownFlag = true
      }
    },
    setCountDown() {
      this.eventCountDownTextTimer = setTimeout(() => {
        this.countDownFn()
      }, 1000)
    },
    countDownFn() {
      if (this.liveIsStop) {
        this.eventCountDownText = '準備中'
        this.eventCountDownTextTimer = setTimeout(() => {
          this.countDownFn()
        }, 25)
      } else {
        this.eventCountDownText = ''
        if (this.meetingeEnterInfo) {
          this.setIcoAnimationDuration()
          this.icoAnimationDurationFlag = true
          window.addEventListener('resize', this.setIcoAnimationDuration)
          if (this.meetingeEnterInfo.ticket) {
            const startTime = this.meetingeEnterInfo.ticket.startTime
            const startWaitTime = this.meetingeEnterInfo.startWaitTime
            const s = moment(startTime).diff(
              new Date().getTime() - this.serverDelay,
              'seconds'
            )
            if (s > 31536000) {
              console.log('新时间还未获取')
              this.eventCountDownText = '--'
              this.eventCountDownTextTimer = setTimeout(() => {
                this.countDownFn()
              }, 25)
            } else if (s > 60) {
              this.eventCountDownText =
                '約' +
                moment(
                  new Date(startTime).getTime() + this.serverDelay
                ).fromNow()
            } else {
              this.eventCountDownText = s + '秒後'
            }
            console.log(
              '时间：',
              new Date(new Date().getTime() - this.serverDelay),
              '进入等待：',
              new Date(startWaitTime)
            )
            if (
              new Date().getTime() - this.serverDelay >=
              new Date(startWaitTime).getTime()
            ) {
              console.log('go to streaming')
              this.$router.replace({
                path: `/user/meeting/${this.id}/streaming`
              })
              setTimeout(() => {
                if (this.$route.name === 'ChatRoom') {
                  console.log('go to streaming fail retry')
                  this.countDownFn()
                } else {
                  if (this.eventCountDownTextTimer) {
                    clearTimeout(this.eventCountDownTextTimer)
                    this.eventCountDownTextTimer = null
                  }
                }
              }, 2000)
            } else {
              this.eventCountDownTextTimer = setTimeout(() => {
                this.countDownFn()
              }, 1000)
            }
          }
        } else {
          setTimeout(() => {
            this.countDownFn()
          }, 25)
        }
      }
    },
    customMsg(data) {
      console.log(data)
      const payloadData = data.payload.data
      if (payloadData === 'good' || payloadData === 'like') {
        const item = {
          type: payloadData,
          id: data.ID
        }
        this.animeWaitIconList.push(item)
        this.setAnimeIcon()
      }
      // const type =
    },
    addNewMsg(message) {
      const msg = {
        avatar: message.avatar,
        name: message.nick || message.from,
        msg: message.payload.text
      }
      this.chatList.push(msg)
    },
    // TODO:test
    addTestIcon() {
      const item = {
        type: random(0, 1) ? 'like' : 'good',
        id: `add${new Date().getTime() - this.serverDelay.getTime()}`
      }
      this.animeWaitIconList.push(item)
      this.setAnimeIcon()
      setTimeout(() => {
        this.addTestIcon()
      }, random(10, 1000))
    },
    clickIcon(type) {
      switch (type) {
        case 'good':
          this.goodIconAnime = true
          break
        case 'like':
          this.likeIconAnie = true
          break
        default:
          break
      }
      this.iconClickCount++
      const item = {
        type: type,
        id: `clickIcon${this.iconClickCount}`
      }
      const newItem = this.setDoingIcon(item)
      const message = this.im.createCustomMessage({
        to: String(this.meetingeEnterInfo.roomId),
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          data: type,
          description: '',
          extension: ''
        }
      })
      this.im.sendMessage(message).then(() => {
        this.animeDoingIconList.push(newItem)
      })
    },
    setDoingIcon(item) {
      const newItem = cloneDeep(item)
      newItem['left'] = random(10000, 90000) / 1000
      newItem['scale'] = random(80, 100) / 100
      return newItem
    },
    deletAnimeListByList(idList) {
      this.animeWaitIconList = this.animeWaitIconList.filter(item => {
        return idList.indexOf(item.id) === -1
      })
    },
    deletAnimeList(id) {
      this.animeDoingIconList = this.animeDoingIconList.filter(item => {
        return item.id != id
      })
      this.animeWaitIconList = this.animeWaitIconList.filter(item => {
        return item.id != id
      })
      this.setAnimeIcon()
    },
    setAnimeIcon() {
      // 動作しているアイコン
      const doingCount = this.animeDoingIconList.length
      const addCount = this.maxAnimeIconCount - doingCount
      // 使ったアイコンリスト
      const usedIdList = []
      for (let i = 0; i < addCount; i++) {
        const item = this.animeWaitIconList[i]
        if (item) {
          const id = item.id
          usedIdList.push(id)
          const newItem = this.setDoingIcon(item)
          this.animeDoingIconList.push(newItem)
        } else {
          break
        }
      }
      this.deletAnimeListByList(usedIdList)
    },
    openCharge() {
      this.pointIsOpen = true
    },
    selectPoint(point, price, pricingId) {
      console.log(point, price, pricingId)
      this.payPoint = String(point)
      this.payJpy = String(price)
      this.payIsOpen = true
      this.pricingId = pricingId
    },
    afterLeave() {
      this.payDialogKey++
    },
    keydownSend(event) {
      console.log(event)
      if (event.keyCode !== 13) {
        return false
      }
      this.send()
    },
    send() {
      console.log(this.chatContent)
      if (this.chatContent) {
        let chatContent = this.chatContent
        this.chatContent = ''
        const message = this.im.createTextMessage({
          to: String(this.meetingeEnterInfo.roomId),
          conversationType: TIM.TYPES.CONV_GROUP,
          priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
          payload: {
            text: chatContent
          }
        })
        this.im.sendMessage(message).then(res => {
          this.chatMousDownFlag = false
          this.chatList.push({
            avatar: this.avatar,
            name: this.$store.getters.meetingUser.nickName,
            msg: chatContent
          })
          console.log('im | sendTextMessage | OK', res)
        })
      }
    },
    //   TODO:テスト用
    throttleChat: throttle(function throttleChat() {
      // debugger
      this.setChatCpntainer()
      this.setMsgMaxCount()
    }, 150),
    setMsgMaxCount() {
      if (this.chatList.length > this.chatMaxCount + 50) {
        this.chatList = this.chatList.slice(
          Math.max(this.chatList.length - this.chatMaxCount, 1)
        )
      }
    },
    setGiftDialog(state) {
      this.isOpen = state
    },
    async openShareModal() {},
    setChatCpntainer() {
      // debugger
      // this.throttleChat()
      this.$nextTick(function() {
        // debugger
        const node = this.$el.querySelector('#chatContainer').lastElementChild
        if (node) {
          if (node.clientHeight === 0) {
            if (this.listScrollTimer) {
              clearTimeout(this.listScrollTimer)
            }
            this.listScrollTimer = setTimeout(() => {
              this.setChatCpntainer()
            }, 10)
          } else {
            if (!this.chatMousDownFlag && !this.iOSkeyBoardIsOpen) {
              scrollIntoView(node, {
                behavior: 'smooth',
                block: 'end'
              })
            } else if (!this.chatMousDownFlag && this.iOSkeyBoardIsOpen) {
              // debugger
              const chatContainer = document.getElementById('chatContainer')
              chatContainer.scrollTop = chatContainer.scrollHeight
            }
            clearTimeout(this.listScrollTimer)
          }
        }
      })
    }
  }
})
</script>

<style scoped>
.chat-room-tricket-room-end-title {
  padding: 2px 0;
}
.chat-room-header-title {
  padding-inline: 0px;
}
.chat-room-tricket-room-info-box {
  background: rgba(75, 74, 75, 0.69);
  border-radius: 18px;
  /* display: inline-block; */
  padding: 0 15px;
}
.chat-room-tricket-info-title {
  margin-bottom: -10px;
  padding-top: 9px;
  font-size: 14px;
}
.chat-room-rebut-btn {
  height: 40px;
  background: #e05193;
  color: #fff;
  line-height: 40px;
  text-align: center;
  width: 100%;
  position: fixed;
  left: 0;
  top: 50%;
  margin-top: -20px;
  z-index: 10;
}
.chat-room-tricket-info-body.chat-room-no-tricket {
  font-size: 14px;
  line-height: 19px;
  padding-top: 10px;
}
.chat-room-bottom-chat-box {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  align-content: flex-end;
  min-height: 100%;
}
.chat-room-bottom-chat-input-send.active {
  color: #fff;
}
.chat-room-bottom-chat-see-new-comment-icon {
  color: #fff;
  vertical-align: -2px;
  padding-left: 10px;
}
.chat-room-bottom-chat-see-new-comment-btn {
  width: 214px;
  height: 45px;
  background-color: #e1dddd;
  border-radius: 4px;
}
.chat-room-bottom-chat-see-new-comment {
  position: absolute;
  z-index: 3;
  bottom: 56px;
  left: 50%;
  margin-left: -107px;
}
.chat-room-audience-list-avatar {
  width: 34px;
  height: 34px;
  border: 2px solid #fff;
  border-radius: 18px;
}
.chat-room-audience-list-body {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0 5% 13px 5%;
  margin-top: 12px;
  overflow: auto;
  position: absolute;
  top: 70px;
  z-index: 6;
}
.chat-room-audience-list-item {
  display: inline-block;
  margin-right: 5px;
}
.chat-room-next-btn {
  background-color: #f23f97;
  color: #fff;
  position: absolute;
  bottom: 0px;
  height: 69px;
  left: 0px;
  width: 100%;
  z-index: 2;
}
.chat-room-right-circle.type-img {
  background-color: transparent;
}
.chat-room-right-circle.type-img img {
  width: 100%;
  height: 100%;
}
.chat-room-right-circle-body {
  padding-right: 14px;
}
.chat-room-right-circle {
  background-color: #8a8a8a;
  height: 34px;
  width: 34px;
  margin-bottom: 8px;
  border-radius: 34px;
  color: #fff;
  line-height: 34px;
  font-size: 20px;
}
.chat-room-right-circle ion-icon {
  padding-top: 6px;
}
.chat-room-right-tool-bar-body {
  position: absolute;
  right: 0;
  bottom: 48px;
  z-index: 2;
  text-align: center;
}
.chat-room-bottom-chat-input-send {
  margin-left: 10px;
  height: 34px;
  width: 57px;
  text-align: center;
  border-radius: 5px;
  border: 0px;
  font-size: 13px;
  background-color: #4b4a4b;
  color: #90949c;
  vertical-align: top;
}
.chat-room-bottom-chat-input-body {
  position: absolute;
  z-index: 2;
  bottom: 0px;
  height: 43px;
  left: 0px;
  padding-left: 10px;
  width: 100%;
}
.chat-room-bottom-chat-input {
  border: 0px;
  width: calc(100% - 82px);
  height: 34px;
  line-height: 34px;
  background-color: rgba(138, 138, 138, 0.3);
  border-radius: 3px;
  border: solid 1px #ced0d4;
  padding: 0 14px;
  vertical-align: top;
  outline: none;
  color: #fff;
}
.chat-room-bottom-chat-input::placeholder {
  color: #9197a3;
}
.chat-room-bottom-chat-input:-ms-input-placeholder {
  color: #9197a3;
}
.chat-room-bottom-chat-input::-ms-input-placeholder {
  color: #9197a3;
}
.chat-room-bottom-chat-name {
  font-size: 12px;
  color: #05ccad;
}
.chat-room-bottom-chat-msg {
  font-size: 12px;
  color: #fbf9f9;
  min-height: 16px;
}
.chat-room-bottom-chat-avatar-box {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}
.chat-room-bottom-chat-avatar {
  height: 35px;
  width: 35px;
  border-radius: 18px;
}
.chat-room-bottom-chat-item {
  position: relative;
  z-index: 1;
  padding-left: 43px;
  padding-bottom: 15px;
  min-height: 35px;
  width: 100%;
  word-break: break-all;
}
.chat-room-bottom-chat-list.type_b {
  bottom: 69px;
  padding-right: 10px;
}
.chat-room-bottom-chat-list {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 43px;
  width: 100%;
  padding-left: 10px;
  padding-right: 64px;
  overflow: auto;
}
.chat-room-bottom-chat-list::-webkit-scrollbar-track-piece {
  background: rgba(255, 255, 255, 0);
}

.chat-room-bottom-chat-list::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.chat-room-bottom-chat-list::-webkit-scrollbar-thumb {
  height: 40px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0);
}
.chat-room-bottom-chat-list:hover::-webkit-scrollbar-thumb {
  height: 40px;
  border-radius: 4px;
  background-color: #bbb;
}

.chat-room-bottom-chat-list::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.chat-room-bottom-chat-bar-box {
  position: relative;
  z-index: 1;
  height: 100%;
}
.chat-room-bottom-chat-bar-body {
  height: 48.7%;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.2) 58%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 2;
  max-width: 720px;
}
.chat-room-tricket-count-body {
  padding-top: 2px;
}
.chat-room-tricket-count-ico {
  padding-bottom: 2px;
  height: 15px;
}
.chat-room-tricket-info-body {
  padding-top: 9px;
  line-height: 25px;
  padding-left: 45px;
}
.chat-room-tricket-time {
  width: 34%;
}
.chat-room-tricket-user {
  width: 65%;
}
.chat-room-tricket-gift {
  width: 46%;
}
.chat-room-tricket-name {
  width: 52%;
}
.chat-room-tricket-avatar-body {
  position: absolute;
  left: 0;
  top: 23px;
}
.chat-room-title-info {
  font-size: 8px;
}
.chat-room-title-body {
  vertical-align: middle;
  font-weight: normal;
  max-width: calc(100vw - 50px);
}
.chat-room-header-avatar {
  display: inline-block;
  height: 32px;
  width: 32px;
  margin-right: 3px;
  border-radius: 16px;
}
.chat-room-tricket-avatar {
  height: 40px;
  width: 40px;
  margin-right: 3px;
  border-radius: 50px;
}
.chat-room-body {
  background-position: center center;
  background-size: cover;
  min-height: calc(100%);
}
ion-content {
  --background: transparent;
}
ion-toolbar {
  --background: transparent;
}
.chat-room-ticket-body {
  position: relative;
  z-index: 1;
  width: 78%;
  margin: 0px auto 0 auto;
}
.chat-room-ticket-mid {
  width: 100%;
  height: 83px;
  background-image: url('/img/red-t-mid.png');
  background-repeat: repeat-x;
  color: #fff;
  font-size: 12px;
}
.chat-room-ticket-left {
  background-size: cover;
  background-image: url('/img/red-t-left.png');
  height: 83px;
  width: 21px;
  position: absolute;
  left: -21px;
  top: 0px;
}
.chat-room-ticket-right {
  background-size: cover;
  background-image: url('/img/red-t-right.png');
  height: 83px;
  width: 21px;
  position: absolute;
  right: -21px;
  top: 0px;
}

.chat-room-white-ticket .chat-room-ticket-mid {
  width: 100%;
  height: 83px;
  background-image: url('/img/white-t-mid.png');
  background-repeat: repeat-x;
  color: #fff;
  font-size: 12px;
}
.chat-room-white-ticket .chat-room-ticket-left {
  background-size: cover;
  background-image: url('/img/white-t-left.png');
  height: 83px;
  width: 21px;
  position: absolute;
  left: -21px;
  top: 0px;
}
.chat-room-white-ticket .chat-room-ticket-right {
  background-size: cover;
  background-image: url('/img/white-t-right.png');
  height: 83px;
  width: 21px;
  position: absolute;
  right: -21px;
  top: 0px;
}

.chat-room-send-icon {
  animation-name: icoAnime;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  opacity: 0;
}
.chat-room-icon-item {
  position: absolute;
  z-index: 2;
  width: 30px;
  height: 30px;
  bottom: 0;
}
.chat-room-body {
  padding-top: 5px;
  height: calc(100%);
  position: relative;
}
.chat-room-icon-body {
  position: relative;
  z-index: 5;
  width: 100%;
  height: calc(100% - 48.7% - 70px);
}
.chat-room-icon-img {
  width: 30px;
  height: 30px;
}
.icon-active {
  -webkit-transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
  transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
  -webkit-animation: gelatine 0.4s 1;
  animation: gelatine 0.4s 1;
}
</style>
<style>
@-webkit-keyframes icoAnime {
  0% {
    bottom: 0%;
    opacity: 0;
  }
  5% {
    bottom: 5%;
    opacity: 1;
  }
  80% {
    bottom: 80%;
    opacity: 1;
  }
  95% {
    bottom: 95%;
    opacity: 0;
  }
  100% {
    bottom: 100%;
    opacity: 0;
  }
}
@keyframes icoAnime {
  0% {
    bottom: 0;
    opacity: 0;
  }
  5% {
    bottom: 5%;
    opacity: 1;
  }
  80% {
    bottom: 80%;
    opacity: 1;
  }
  95% {
    bottom: 95%;
    opacity: 0;
  }
  100% {
    bottom: 100%;
    opacity: 0;
  }
}
@keyframes gelatine {
  from,
  to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }

  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  from,
  to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }

  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}
@-webkit-keyframes gelatine {
  from,
  to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }

  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  from,
  to {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  25% {
    -webkit-transform: scale(0.9, 1.1);
    transform: scale(0.9, 1.1);
  }

  50% {
    -webkit-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  75% {
    -webkit-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }
}
@media all and (orientation: landscape) {
  .chat-room-page {
    background-size: contain !important;
    background-repeat: no-repeat;
  }
}
</style>
